// CSS Style
import 'styles/site.scss'
import 'styles/style.min.css'
import 'styles/custom.scss'

// Other JS
import('admin/js/site')
import 'admin/js/notification'
import ahoy from "ahoy.js"
import c3 from 'c3'

// 因為在 webpack 打包的時候，沒有瀏覽器的 window 物件，所以必須 assign 這樣才能在 rails view 使用
// Ref: https://tinyurl.com/ycrtavmd
window.jQuery = $
window.$ = $
window.c3 = c3
